import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import deepOrange from '@material-ui/core/colors/deepOrange'

import CQgrey from '../theme/colors/cq-grey'
import CQgreen from '@material-ui/core/colors/lightGreen'

import purple from '@material-ui/core/colors/purple'
import blueGrey from '@material-ui/core/colors/blueGrey'

import {currentDomain} from '../@react-fefmwk/requests/api'
import {
    getToken,
    decodeToken
} from '../@react-fefmwk/auth/services/token'

export function getAgencyFromDomain( domain = '' ) {
    domain = !domain ? currentDomain() : domain

    switch (domain) {
        case 'gestionale.demo.stage.it':
        case 'gestionaledemo.teuteca.it':
        case 'gestionale.demo.com':
            return 'demo'
        case 'gestionale.chequinto.stage.it':
        case 'gestionalechequinto.teuteca.it':
        case 'gestionale.chequinto.com':
            return 'chequinto'
        case 'gestionale.ilquinto.stage.it':
        case 'gestionaleilquinto.teuteca.it':
        case 'gestionale.ilquinto.net':
            return 'ilquinto'
    }
}

export function agenciesBranchesFromToken() {
    const token = getToken()
    if( !token ) return []
    const payload = decodeToken(token)
    return payload.data.agencies_branches ? payload.data.agencies_branches : []
}
export function agencySchema( agency = '' ) {
    agency = !agency ? getAgencyFromDomain() : agency

    switch (agency) {
        case 'demo':
            grey.main = grey[700]
            deepOrange.main = deepOrange[600]
            return {
                slug: 'demo',
                name: 'Agenzia demo',
                title: 'Agenzia demo - gestionale',
                palette: {
                    primary: deepOrange,
                    secondary: grey,
                    grey: grey,
                    success: green,
                    error: red,
                    warning: amber,
                    info: blue,
                },
                assets_path: '/images/demo',
                serviceUrl_prod: 'https://api.gestionaledemo.teuteca.it/',
                serviceUrl_dev: 'http://api.gestionale.demo.stage.it',
                domain_dev: 'gestionale.demo.stage.it',
                leads_fetch: false,
                btn_dashboard_addTask: false,
            }
        case 'chequinto':
            CQgreen.main = CQgreen[500]
            CQgrey.main = CQgrey[600]
            return {
                slug: 'chequinto',
                name: 'Che Quinto',
                title: 'Che Quinto - gestionale',
                palette: {
                    primary: CQgreen,
                    secondary: CQgrey,
                    grey: grey,
                    success: green,
                    error: red,
                    warning: amber,
                    info: blue,
                },
                assets_path: '/images/chequinto',
                serviceUrl_prod: 'https://api.gestionalechequinto.teuteca.it/',
                serviceUrl_dev: 'http://api.gestionale.chequinto.stage.it',
                domain_dev: 'gestionale.chequinto.stage.it',
                leads_fetch: true,
                btn_dashboard_addTask: false,
            }
        case 'ilquinto':
            purple.main = purple[500]
            blueGrey.main = blueGrey[600]
            return {
                slug: 'ilquinto',
                name: 'IlQuinto.net',
                title: 'IlQuinto.net - gestionale',
                palette: {
                    primary: purple,
                    secondary: blueGrey,
                    grey: grey,
                    success: green,
                    error: red,
                    warning: amber,
                    info: blue,
                },
                assets_path: '/images/ilquinto',
                serviceUrl_prod: 'https://api.gestionaleilquinto.teuteca.it/',
                serviceUrl_dev: 'http://api.gestionale.ilquinto.stage.it',
                domain_dev: 'gestionale.ilquinto.stage.it',
                leads_fetch: false,
                btn_dashboard_addTask: true,
            }
        default:
            return {}
    }
}